<template>
  <div class="ml-5 mr-5">
    <div class="text-right ">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
<div class="text-left">
    <v-container fluid>
        <v-row>
         <v-col cols="6">
      <form>
        <v-text-field
          v-model="query"
          label="Search By Communication Name"
          required
        ></v-text-field>
      </form>
          </v-col>
          <v-col cols="3" class="my-4">
             <v-btn small color="primary" @click="getCommunication(1)">
              Search
        </v-btn>
        <v-btn small @click="resetSearch()">
              Reset
        </v-btn>
         </v-col>
         <v-col cols="3" class="my-4 text-right">
           <template>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="mx-2" color="primary" dark v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('add')">
              Add
            </v-btn>
          </template>
          <v-card class="pl-5 pr-5">
            <v-card-title>
              <span class="headline">Add Communication Type</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit="addCommunicationData"
              method="post"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                  v-model="addCommunication.communicationName"
                  label="Communication Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <div style="color: red">
                  {{ message1 }}
                </div>
              <v-select
                  v-model="addCommunication.audience"
                  :items="audiences"
                  :menu-props="{ maxHeight: '400' }"
                  label="Select Audience"
                  multiple
                ></v-select>
                <v-subheader style="font-size:10px;padding:0px;height:20px">Sectors</v-subheader>
                <div style="max-height:250px;overflow-y:scroll;width:auto;overflow-x: hidden;border:1px solid #ccc;">
                <SectorsUpdateForm v-bind:items="sectors" v-bind:selection="addCommunication.sector" @updateSectors="addSectors" />
                </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" type="submit" @click="validate">
                  Submit
                </v-btn>
                <v-btn class="close_button mx-2" text @click="reset"> Close </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>

      <v-dialog max-width="600" v-model="dialog2">
        <template v-slot:activator="{ on, attrs }" >
          <v-btn small color="error" v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('delete')">Delete</v-btn>
        </template>
        <div v-if="deleteItems.length > 0">
          <template>
            <v-card>
              <v-card-text>
                <div class="text-h3 pa-12">
                  Are you sure you want to delete all the selected Record(s)
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                  <v-btn
                    text
                    class="confirm_button mx-2"
                    v-on:click="dialog2 = false"
                    @click="deleteCommunicationType"
                    >Yes</v-btn
                  >
                </v-form>
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
        <div v-if="deleteItems.length == 0">
          <template>
            <v-card>
              <v-card-text class="center">
                <div class="text-h3 pa-12">
                  Please Select Record(s) to delete
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
      </v-dialog>
         </v-col>
       </v-row>
     </v-container>
</div>
    </div>

    <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <div id="select-all-checkbox" v-show="checkIfOperationExistForModule('delete')">
              <v-checkbox
                ref="n"
                v-model="allSelected"
                @click="selectAll"
              ></v-checkbox>
            </div>
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :page.sync="page"
              :items="communication_data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item.selectCheckbox="{ item }">
                <v-checkbox
                  ref="n"
                  v-model="deleteItems"
                  :value="item.communication_id"
                   :key="item.communication_id"
                   v-show="checkIfOperationExistForModule('delete')"
                  @click="addToDelete($event, item.communication_id)"
                ></v-checkbox>
              </template>

               <template v-slot:item.actions="{ item }">
                <v-icon
                      medium
                      color="grey"
                      @click="
                        addToUpdate($event, item.communication_id)
                      "
                      v-show="checkIfOperationExistForModule('edit')"
                    >
                      mdi-pencil
                    </v-icon>
              </template>
            </v-data-table>
            <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>

            <div></div>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
     <v-dialog
      v-model="toggleUpdateModal"
      max-width="600"
    >
      <template>
	  <v-card class="pl-5 pr-5">
                    <v-card-title>
                      <span class="headline">Update Communication</span>
                    </v-card-title>
                    <v-form
                      ref="form"
                      @submit="updateCommunicationData"
                      method="put"
                    >
                      <v-text-field
                          v-model="updateCommunication.communicationName"
                          label="Communication Name"
                          :rules="nameRules"
                          required
                        ></v-text-field>
                        <div style="color: red">
                          {{ upmessage1 }}
                        </div>
                      <v-select
                          :items="audiences"
                          label="Select Audience"
                          multiple
                          v-model="updateCommunication.audienceId"
                        ></v-select>
                        <v-subheader style="font-size:10px;padding:0px;height:20px">Sectors</v-subheader>
                        <div style="max-height:250px;overflow-y:scroll;width:auto;overflow-x: hidden;border:1px solid #ccc;">
                          <SectorsUpdateForm v-bind:items="sectors" v-bind:selection="updateCommunication.sectors" v-bind:addUpdate="'update'" @updateSectors="updateSectors" />
                        </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          @click="updateCommunicationData"
                          type="submit"
                        >
                          Update
                        </v-btn>
                        <v-btn class="close_button mx-2" text @click="reset">
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
	  </template>
     </v-dialog>
</v-row>
        <!-- v-data-table-ends -->


  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "./component/Pagination.vue";
import SectorsUpdateForm from "./component/SectorsUpdateForm.vue";

export default {
  components: { Index, Buttons, Loading, Pagination, SectorsUpdateForm },
  data() {
    return {
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      allParentNodes : true,
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "5%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Communication Type",
          value: "communication_name",
          width: "35%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Audience",
           width: "50%",
          value: "communication_audie",
          sortable: false,
          class: "v-data-table-header",
        },
        // {
        //   text: "Sector",
        //   value: "communication_sector",
        //   sortable: false,
        //   class: "v-data-table-header",
        // },
        {
          text: "Action",
          value: "actions",
           width: "10%",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      ///
      valid: true,
      checkbox: false,
      fullPage: true,
      communication: [],
      dialog: false,
      dialog1: false,
      dialog2: false,
      audiences: [],
      page: 1,
      query: "",
      categ: "",
      category: "",
      deleteItems: [],
      checked_communicationid: [],
      checked_sectorId: [],
      checkcedstr: "",
      categor_count: [],
      product_data: [],
      productCount: [],
      communicationIds: [],
      communication_data: [],
      pageNo: 0,
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      communicationNames: [],
      addCommunication: {
        communicationName: null,
        audience: [],
        sector: [],
      },
      updateCommunication: {
        communicationId: null,
        communicationName: null,
        audienceId: [],
        sectors: [],
      },
      checked: false,
      sectors: [],
      nameRules: [(v) => !!v || "Name is required"],
      showPerPage: false,
      selectionAdd:[],
    };
  },
  computed: {
    _sectors () {
       const replaceChildren = (obj,parent) => {
         const clone = Object.assign({},obj)
         delete clone.children
         if (parent) clone.parent = parent
         return clone
       }

       const addItems = (arr,parent) => {
         const items = arr.reduce((acc,x)=>{

           acc.push(replaceChildren(x,parent))

           if (x.children) {
             acc.push(addItems(x.children, x.id))
           }
           return acc
         },[])

         return items.flat()
       }

       return addItems(this.sectors).reduce((acc,x)=>{
         acc[x.id]=x
         return acc
       },{})
    },
    _selectionAdd () {
       const proxy = []
       const addParents = (x, all) => {
         const parentId = this._sectors[x.id].parent
         if (parentId) {
           if (all) addParents(this._sectors[parentId])
           proxy.push(parentId)
         }
       }
       const unique = (value, index, self) => {
          return self.indexOf(value) === index
       }
       this.selectionAdd.forEach(x=>{
         addParents(x,this.allParentNodes)
         proxy.push(x.id)
       })
      return proxy.filter(unique)
    },
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
    selectedValues(x){
      console.log(x);
    },
    //pagination methods
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getCommunication(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getCommunication(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getCommunication(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getCommunication(this.page);
    },
    /////
     resetSearch(){
      this.page = 1,
      this.query = ''
      this.getCommunication(1);
     },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.toggleUpdateModal = false
      this.message1 = "";
      this.upmessage1 = "";
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.addCommunication.sector = []
      this.updateCommunication.sectors = []
    },
    getCommunication(page = "") {
      let check = this;
      let page_no = page == '' ? this.page : page
      let searchData ={
        "q": check.query.trim(),
        "page": page_no
      }
      const path = check.$url("COMMUNICATION_SEARCH");
      //const path = 'http://0.0.0.0:5011/communication-search'
      check.isLoading = true;
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
        .post(path,searchData)
        .then((res) => {
          check.isLoading = false;
          console.log(res.data.payload);
          check.communication = res.data.payload[0];
          console.log(check.totRecords, res.data.payload[4]);
           if(check.totRecords != res.data.payload[4]){
            check.page = 1
          }
          check.totRecords = res.data.payload[4];
          check.total = check.communication.length;
          check.pageCount = res.data.payload[2];
          check.sectors = res.data.payload[1];

          if (res.data.payload[3].length) {
            res.data.payload[3].forEach(function (item) {
              let a = {
                text: item[1],
                value: item[0],
              };
              check.audiences.push(a);
            });
          }
          let communication = this.communication;
          check.communication_data = [];
          communication.forEach(function (communication_audie, i) {
            if (communication_audie.audienceName == undefined) {
              communication_audie.audienceName = [];
            }
            if (communication_audie.sectorName == undefined) {
              communication_audie.sectorName = [];
            }
            let tempobj = {
              communication_id: communication_audie.communicationTypeId,
              communication_name: communication_audie.communicationType,
              communication_audie: communication_audie.audienceName.toString(),
              // communication_sector: communication_audie.sectorName.toString(),
            };
            check.communication_data.push(tempobj);
          });

          if (this.communication == 0) {
            this.communication_data = [];
            this.communicationIds = [];
          } else {
            let temp = this;
            temp.communicationIds = [];
            temp.communication.forEach(function (o, i) {
              if (o.communicationTypeId !== undefined) {
                temp.communicationIds.push(o.communicationTypeId);
              }
            });
          }
        }).catch((error) => {
           console.error(error);
           check.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
    },
    addCommunicationData(e) {
      e.preventDefault();
      let check = this;
      this.isLoading = true
      if (
        this.addCommunication.communicationName != null &&
        this.addCommunication.communicationName != ""
      ) {
        //'http://0.0.0.0:5011/communication-search' this.$url("COMMUNICATION")
        this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: check })
          .post(this.$url("COMMUNICATION"), this.addCommunication)
          .then((result) => {
            check.isLoading = false
            console.log(result);
            // this.message1 = result.data.payload[0].communicationID;
            // this.message2 = result.data.payload[0].communicationName;
            //console.log(result.data.payload[0].communicationID);
            if (result.data.payload[0].error != undefined) {
              //this.message1 = result.data.payload[0].error;
              this.dialog = true;
              this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: result.data.payload[0].error,
                });
            } else {
              this.message1 = null;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.getCommunication();
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) added successfully",
                  text: result.data.payload.message,
                });
              }
              this.dialog = false;
              check.reset();
              this.addCommunication.sector = []
            }
            console.log(result);
          }).catch((error) => {

           console.error(error);
           check.isLoading = false
          //  this.$swal.fire({
          //         icon: "error",
          //         title: "Error",
          //         text: "Something went wrong!",
          //       });
        });
      }
    },
    addToUpdate(e, atchid) {
      let view = this;
      this.toggleUpdateModal = true
      view.communication.forEach(function (o, i) {
        if (o.communicationTypeId == atchid) {
          view.updateCommunication.communicationName = o.communicationType;
          view.updateCommunication.communicationId = o.communicationTypeId;
          view.updateCommunication.audienceId = [];
          if(o.audienceId != '' && o.audienceId != null){
            o.audienceId.split(",").forEach(function (item) {
            view.updateCommunication.audienceId.push(parseInt(item));
          });
          }
          view.updateCommunication.sectors = [];
          if(o.sectorId != '' && o.sectorId != null){
            o.sectorId.split(",").forEach(function (item) {
            view.updateCommunication.sectors.push(parseInt(item));
          });
          }
        }
      });
    },
    updateSectors(d){
      this.updateCommunication.sectors = d
    },
    addSectors(d){
      this.addCommunication.sector = d
    },
    updateCommunicationData(e) {
      e.preventDefault();
      let data = this;
      this.isLoading = true
      if (
        this.updateCommunication.communicationName != null &&
        this.updateCommunication.communicationName != ""
      ) {
        //'http://0.0.0.0:5011/communication-search' this.$url("COMMUNICATION")
        this.$fetch({ requiresAuth: true, operation: 'edit', vueScope: data })
          .put(this.$url("COMMUNICATION"), this.updateCommunication)
          .then((result) => {
            data.isLoading = false
            // this.upmessage1 = result.data.payload.communicationID;
            // this.upmessage2 = result.data.payload.communicationName;
            if (result.data.payload.error != undefined) {
              //this.upmessage1 = result.data.payload.error;
              this.dialog1 = true;
              this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: result.data.payload.error,
                });
            } else {
              this.upmessage1 = null;
              this.dialog1 = false;
              if (result.data.statusCode == 200) {
                this.getCommunication();
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) updated successfully",
                  text: result.data.payload.message,
                });
                data.toggleUpdateModal = false
              }
              this.reset();
            }
            console.log(result);
          }).catch((error) => {
           console.error(error);
           data.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      if (e.target.checked) {
        if (this.checked_communicationid.indexOf(e.target.value) == -1) {
          this.checked_communicationid.push(e.target.value);
        }
      } else {
        this.checked_communicationid.splice(
          this.checked_communicationid.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_communicationid.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteCommunicationType(e) {
      e.preventDefault();
      let check = this;
      this.isLoading = true
      console.log(this.deleteItems);
      this.$fetch({ requiresAuth: true, operation : 'delete', vueScope: check })
        .delete(this.$url("COMMUNICATION"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          check.isLoading = false
          if (response.data.statusCode == 200) {
            this.getCommunication();
            this.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.payload.message,
            });
          }
          this.deleteItems = [];
        }).catch((error) => {
           console.error(error);
           check.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
    },
    selectAll: function () {
      let delcommunication = this.communicationIds;
      console.log(delcommunication);
      if (this.allSelected == true) {
        if (Array.isArray(delcommunication) && delcommunication.length) {
          this.deleteItems = [];
          this.deleteItems = delcommunication;
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    findSubCateg(atchid) {
      let view = this;

      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view })
        .get(view.$const.baseUrlComType, { params: { parentId: atchid } })
        .then((res) => {
          console.log(res.data.payload.data[4]);
          if (res.data.payload.data[4].length) {
            res.data.payload.data[4].forEach(function (item) {
              item.forEach(function (o) {
                let a = {
                  text: o[1],
                  value: o[0],
                };
                view.subcategories.push(a);
              });
            });
          }
        });
    },
    submit() {
      this.$v.$touch();
    },
    // searchCommunicationType: function () {
    //   this.getCommunication(this.page, this.query);
    //   // this.deleteItems = [0];
    //   console.log(this.query);
    // },
  },
  watch: {
 '$store.state.Rawdata.current_active_module': function(o) {
 if(o){
 this.getCommunication(1);
 }
 },
 page: function (ob) {
 this.allSelected = false;
 this.deleteItems = [];
 //this.getCommunication(ob, this.query);
 },
 },
};
</script>
